import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHeading from "@common/PageHeading/PageHeading";
import { Col, Container } from "react-grid-system";
import styled from "styled-components";
import {
    MOBILE_MIN_WIDTH,
    TABLET_MAX_WIDTH,
    PROCESS_SECTION_ITEM_HEIGHT,
    HEADER_HEIGHT,
    SUPPORT_BANNER_HEIGHT,
} from "@constants";
import GifAnimation from "@common/GifAnimation/GifAnimation";
import BrowserDetect from "@utils/browserDetect";
import DesktopProcessListItem from "./DesktopProcessListItem/DesktopProcessListItem";

const DesktopProcessListSection = ({
    ProcessItemsInfo,
    gifAnimation,
    isMobile,
    heading,
    itemsQuantity,
}) => {
    const ContainerRef = useRef(null);
    const WrapperRef = useRef(null);
    const GIFImageRef = useRef(null);
    const GIFStartedPosition = useRef(null); // useRef is using to keep it updated in callback func
    const [ContainerSize, setContainerSize] = useState(null);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    const [isSectionEnded, setIsSectionEnded] = useState(false);
    const [GIFCurrentPosition, setGIFCurrentPosition] = useState(null);
    const [GIFTopOffset, setGIFTopOffset] = useState(0);
    console.log(
        ContainerSize,
        "ContainerSize",
        GIFTopOffset,
        "GIFTopOffset",
        isSectionEnded,
        "isSectionEnded"
    );
    const getGIFMovement = () => {
        if (!GIFStartedPosition.current) return 0;

        const currentPosition =
            window.scrollY +
            window.innerHeight -
            (GIFStartedPosition.current + window.innerHeight / 2) -
            HEADER_HEIGHT -
            SUPPORT_BANNER_HEIGHT;

        return currentPosition < 0 ? 0 : currentPosition;
    };

    const isSectionScrolling = () => {
        if (!ContainerSize) return;

        if (
            GIFCurrentPosition ===
            ContainerSize.bottom -
                ContainerSize.y -
                PROCESS_SECTION_ITEM_HEIGHT / 2 -
                HEADER_HEIGHT / 2
        ) {
            return false;
        }

        if (GIFCurrentPosition === 0) {
            return false;
        }

        return true;
    };

    const updateGIFPosition = () => {
        let currentMovement = getGIFMovement();

        if (currentMovement === 0) {
            setIsSectionEnded(false);
        }

        if (
            currentMovement >
            ContainerSize.bottom -
                ContainerSize.y -
                PROCESS_SECTION_ITEM_HEIGHT / 2 -
                HEADER_HEIGHT / 2
        ) {
            setIsSectionEnded(true);
            currentMovement =
                ContainerSize.bottom -
                ContainerSize.y -
                PROCESS_SECTION_ITEM_HEIGHT / 2 -
                HEADER_HEIGHT / 2;
        } else if (
            GIFImageRef.current.getBoundingClientRect().top +
                PROCESS_SECTION_ITEM_HEIGHT / 2 <=
            window.innerHeight / 2
        ) {
            setGIFTopOffset(prevState =>
                !prevState
                    ? window.innerHeight / 2 -
                      HEADER_HEIGHT -
                      SUPPORT_BANNER_HEIGHT
                    : prevState
            );
        }

        setGIFCurrentPosition(currentMovement);
    };

    const calculateRatio = useCallback(
        count => (PROCESS_SECTION_ITEM_HEIGHT * itemsQuantity) / count,
        []
    );

    useEffect(() => {
        setContainerSize(ContainerRef.current.getBoundingClientRect());

        const sectionObserver = new IntersectionObserver(
            entries => {
                setIsSectionVisible(entries[0].isIntersecting);
            },
            { root: null, threshold: [0], rootMargin: "160px" }
        );

        sectionObserver.observe(WrapperRef.current);

        return () => sectionObserver.disconnect();
    }, []);

    useEffect(() => {
        if (!ContainerSize) return;
        const imageObserver = new IntersectionObserver(
            entries => {
                if (!entries[0].isIntersecting && !isSectionEnded) {
                    GIFStartedPosition.current = null;
                }
            },
            { root: null, threshold: [1] }
        );

        imageObserver.observe(GIFImageRef.current);

        return () => imageObserver.disconnect();
    }, [ContainerSize, isSectionEnded]);

    useEffect(() => {
        const onScroll = () => {
            if (
                Math.round(GIFImageRef.current.getBoundingClientRect().top) <=
                window.innerHeight / 2
            ) {
                if (!GIFStartedPosition.current) {
                    GIFStartedPosition.current =
                        window.innerHeight / 2 + window.scrollY;
                    GIFStartedPosition.current -=
                        window.innerHeight / 2 -
                        GIFImageRef.current.getBoundingClientRect().top;
                }
            }

            updateGIFPosition();
        };

        if (isSectionVisible) {
            document.addEventListener("scroll", onScroll);
        } else {
            document.removeEventListener("scroll", onScroll);
        }

        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [isSectionVisible]);

    return (
        <Wrapper ref={WrapperRef}>
            <PageHeading heading={heading} />
            <ProcessItemsContainer
                className="process-item__container"
                ref={ContainerRef}
            >
                <Container
                    fluid
                    style={{
                        marginBottom: !isMobile ? "5rem" : "2.5rem",
                        display: "flex",
                    }}
                >
                    <Col lg={6}>
                        <GifAnimation
                            gifElementRef={GIFImageRef}
                            gifAnimation={gifAnimation}
                            calculateRatio={count => calculateRatio(count)}
                            calculateFrame={ration =>
                                Math.round(getGIFMovement() / ration)
                            }
                            isPlay={isSectionVisible}
                        >
                            <div
                                className="app"
                                ref={GIFImageRef}
                                style={{
                                    transform: !isSectionScrolling()
                                        ? `translateY(${GIFCurrentPosition}px)`
                                        : null,
                                    position:
                                        isSectionScrolling() && GIFTopOffset
                                            ? "fixed"
                                            : "relative",
                                    top: isSectionScrolling()
                                        ? GIFTopOffset
                                        : null,
                                    zIndex: BrowserDetect.isSafari()
                                        ? "0"
                                        : "-10",
                                }}
                            />
                        </GifAnimation>
                    </Col>
                    <Col lg={6}>
                        {ProcessItemsInfo.map((processItemInfo, index) => (
                            <DesktopProcessListItem
                                heading={processItemInfo.heading}
                                description={processItemInfo.description}
                                itemsQuantity={itemsQuantity}
                                number={index}
                                key={index}
                                isSectionVisible={isSectionVisible}
                            />
                        ))}
                    </Col>
                </Container>
            </ProcessItemsContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    & .page-heading {
        text-align: left;
    }
`;

const ProcessItemsContainer = styled.div`
    margin: 5rem 0 ${160 + PROCESS_SECTION_ITEM_HEIGHT / 2}px;

    canvas {
        width: 33rem;
        height: 21rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-bottom: 6.75rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin: 2.5rem 0 5rem;
    }
`;

export default DesktopProcessListSection;
