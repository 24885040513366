import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH, HEADER_HEIGHT } from "@constants";

const DesktopProcessListItem = ({
    heading,
    description,
    number,
    itemsQuantity,
}) => {
    const TextContainerRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                setIsActive(entries[0].isIntersecting || number === 0);
            },
            {
                root: null,
                threshold: [0],
                rootMargin: `-${(window.innerHeight - HEADER_HEIGHT) / 2}px`,
            }
        );

        observer.observe(TextContainerRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <TextContainer
            isLast={number + 1 === itemsQuantity}
            isActive={isActive}
            ref={TextContainerRef}
        >
            <h3>{heading}</h3>
            <p>{description}</p>
        </TextContainer>
    );
};

const TextContainer = styled.div`
    margin-left: 5rem;
    height: ${props => (props.isLast ? "fit-content" : "21.875rem")};
    margin-bottom: ${props => (props.isLast ? 0 : "2.5rem")};
    opacity: ${props => (!props.isActive ? 0.2 : null)};
    transition: 0.5s;

    & p {
        font-weight: 200;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--main-font-color);
        margin: 0;
        transition: 0.5s;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-left: -0.9275rem;
    }

    & h3 {
        opacity: 0.8;
        transition: 0.5s;
        font-size: 2.375rem;
        line-height: 2.375rem;
        margin: 1.5rem 0;

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            line-height: normal;
        }
    }
`;

export default DesktopProcessListItem;
